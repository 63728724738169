import { Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { BN } from "@zilliqa-js/util";
import { bytes, fromBech32Address } from "@zilliqa-js/zilliqa";
import { DialogModal } from "app/components";
import { actions } from "app/store";
import { TBMConnector } from "app/tbm";
import { useAsyncTask, useNetwork, useRedux } from "app/utils";
import { BIG_ONE, ContractsBech32 } from "app/utils/constants";
import cls from "classnames";
import { logger } from "core/utilities";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ObservedTx } from "zilswap-sdk";
import { Network } from "zilswap-sdk/lib/constants";
import { ReactComponent as TinyBearSVG } from "./asset/tbm-icon-bear.svg";
import { CustomLoader } from "./components";

export interface MintDialogProps {
  open: boolean;
  onClose: () => void;
  amount: number;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  titleText: {
    color: "#FF5252",
    fontSize: "35px",
    lineHeight: "40px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "28px",
      lineHeight: "35px",
    },
  },
  heroColor: {
    color: "#FF5252",
  },
  warningText: {
    fontSize: "20px",
  },
  boxPadding: {
    padding: theme.spacing(4, 12),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(3, 4),
    },
  },
  tinybear: {
    "& #bear": {
      fill: "#D39367",
    },
    marginLeft: theme.spacing(1),
    alignSelf: "center",
    height: "30px",
    width: "30px",
  },
  amount: {
    color: "#511500",
    fontSize: "35px",
    lineHeight: "50px",
  },
  mintButton: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    height: 70,
    width: "100%",
    borderRadius: "20px",
    backgroundColor: "#FF5252",
    textDecoration: "none!important",
    "& .MuiTypography-root": {
      fontSize: "35px",
      lineHeight: "50px",
      color: "#FFFFFF",
    },
    "&:hover": {
      backgroundColor: "#FF5252",
    },
    [theme.breakpoints.down("md")]: {
      alignSelf: "center",
    },
  },
  buttonText: {
    color: "#FFFFFF",
    fontSize: "25px!important",
    lineHeight: "44px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px!important",
      lineHeight: "32px",
    },
  },
  noBottomMargin: {
    marginBottom: 0,
  },
  decButtonMargin: {
    marginTop: theme.spacing(1),
    marginBottom: 0,
  },
  viewTransaction: {
    fontWeight: 800,
    fontSize: "20px",
  },
  cost: {
    color: "#511500",
    fontSize: "20px",
    lineHeight: "35px",
  },
}));

const BEAR_PRICE = BIG_ONE.times(2000).shiftedBy(12);

const CHAIN_ID = {
  [Network.TestNet]: 333, // chainId of the developer testnet
  [Network.MainNet]: 1, // chainId of the mainnet
};

const msgVersion = 1; // current msgVersion

const MintDialog: React.FC<MintDialogProps> = (props: MintDialogProps) => {
  const { open, onClose, amount } = props;
  const classes = useStyles();
  const network = useNetwork();
  const [runMint, minting] = useAsyncTask("mint", () => {
    setStatus("done");
  });
  const [status, setStatus] = useState<undefined | "pending" | "done">();
  const dispatch = useDispatch();

  const tokenState = useRedux((state) => state.token);
  const { currentMinting } = tokenState;
  const contractAddresses = ContractsBech32[network];

  const handleMint = () => {
    runMint(async () => {
      await mint(amount);
    });
  };

  const handleOnClose = () => {
    dispatch(actions.Token.updateCurrentMinting(null));
    setStatus(undefined);
    onClose();
  };

  // TODO: clean up functions
  const mint = async (mintQty: number) => {
    const tbmConnector = TBMConnector.getSDK();

    if (!tbmConnector.zilliqa) throw new Error("Wallet not connected");
    setStatus("pending");

    const communityMinterAddress = fromBech32Address(
      contractAddresses.CommunityMinter
    );

    const chainId = CHAIN_ID[network];
    const minterContract = tbmConnector.getContract(communityMinterAddress);
    const args = [
      {
        vname: "quantity",
        type: "Uint32",
        value: `${mintQty}`, // to be amended
      },
    ];

    const minGasPrice = (
      await tbmConnector.zilliqa.blockchain.getMinimumGasPrice()
    ).result as string;
    const params: any = {
      amount: new BN(BEAR_PRICE.times(mintQty).toString(10)),
      gasPrice: new BN(minGasPrice),
      gasLimit: "30000",
      version: bytes.pack(chainId, msgVersion),
    };
    const mintTx = await tbmConnector.callContract(
      minterContract,
      "MintForCommunity",
      args,
      params,
      true
    );
    logger("mint tx dispatched", mintTx.id);

    if (mintTx.isRejected()) {
      throw new Error("Submitted transaction was rejected.");
    }

    const observeTxn: ObservedTx = {
      hash: mintTx.id!,
      deadline: Number.MAX_SAFE_INTEGER,
    };

    await tbmConnector.observeTx(observeTxn);
    logger("mint observe tx: ", observeTxn);

    dispatch(actions.Token.updateCurrentMinting(observeTxn));
    return observeTxn;
  };

  const getContent = () => {
    if (currentMinting?.status === "confirmed") {
      return (
        <Box className={classes.boxPadding}>
          <Typography variant="h1" className={classes.titleText}>
            Minting Success!
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            mt={2}
          >
            <Typography className={classes.amount}>
              {amount} <b className={classes.heroColor}>x</b>{" "}
            </Typography>
            <TinyBearSVG className={classes.tinybear} />
          </Box>

          <Typography className={classes.viewTransaction}>
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://viewblock.io/zilliqa/tx/0x${
                currentMinting.hash
              }?network=${network.toLocaleLowerCase()}`}
              style={{ color: "#511500" }}
            >
              view transaction
            </a>
          </Typography>

          <Box alignItems="center" display="flex" flexDirection="column">
            <Button
              className={cls(classes.mintButton, classes.noBottomMargin)}
              onClick={handleOnClose}
              disableFocusRipple
            >
              <Typography className={classes.buttonText}>Mint more!</Typography>
            </Button>
            <Button
              className={cls(classes.mintButton, classes.decButtonMargin)}
              component={Link}
              to="/gallery"
              onClick={handleOnClose}
              disableFocusRipple
            >
              <Typography className={classes.buttonText}>
                View my bears
              </Typography>
            </Button>
          </Box>
        </Box>
      );
    }

    if (status === "pending") {
      return (
        <Box className={classes.boxPadding}>
          <Typography variant="h1" className={classes.titleText}>
            Minting transaction <br /> initiated
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            mt={2}
            mb={6}
          >
            <Typography className={cls(classes.heroColor, classes.warningText)}>
              Don't close this page! <br />
              Bear with us...
            </Typography>
          </Box>
          <CustomLoader large={true} />
          <Box mb={4} />
        </Box>
      );
    }

    return (
      <Box className={classes.boxPadding}>
        <Typography variant="h1" className={classes.titleText}>
          Mint my bear
        </Typography>
        <Box display="flex" flexDirection="row" justifyContent="center">
          <Typography className={classes.amount}>
            {amount} <b className={classes.heroColor}>x</b>{" "}
          </Typography>
          <TinyBearSVG className={classes.tinybear} />
        </Box>

        <Typography className={classes.cost}>
          <span style={{ color: "#FF5252" }}>Adopt for</span>{" "}
          {BEAR_PRICE.times(amount).shiftedBy(-12).toFormat(0)} ZIL
        </Typography>

        <Button
          className={classes.mintButton}
          onClick={handleMint}
          disableFocusRipple
          fullWidth
        >
          <Typography className={classes.buttonText}>Mint</Typography>
        </Button>
      </Box>
    );
  };

  const disabledClose = (currentMinting && !currentMinting.status) || minting;
  return (
    <DialogModal
      open={open}
      onClose={!disabledClose ? handleOnClose : undefined}
    >
      {getContent()}
    </DialogModal>
  );
};

export default MintDialog;
