import { Box, Button, CircularProgress, ClickAwayListener, makeStyles, Paper, Popper, Theme, Typography } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import cls from "classnames";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { connectWalletZilPay } from "../../../core/wallet";
import { actions } from "../../store";
import { ConnectWalletResult } from "../../store/types";
import { truncate, useAsyncTask, useNetwork, useRedux, useTaskSubscriber } from "../../utils";
import { LoadingKeys, LocalStorageKeys } from "../../utils/constants";
import { useSnackbar } from "notistack";

const ConnectWalletButton: React.FC<
  React.HTMLAttributes<HTMLDivElement>
> = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const wallet = useRedux((state) => state.wallet.wallet);
  const { enqueueSnackbar } = useSnackbar();

  const [runConnectTask] = useAsyncTask<void>("connectWalletZilPay", (error) => {
    enqueueSnackbar("Error connecting to ZilPay");
  });
  const [isLoading] = useTaskSubscriber(...LoadingKeys.connectWallet);
  const dispatch = useDispatch();
  const network = useNetwork();

  useEffect(() => {
    if (localStorage.getItem(LocalStorageKeys.ZilPayConnected) === "true" && !wallet) {
      setTimeout(handleConnectWallet, 500); // wait for zilPay initialization
    }

    // eslint-disable-next-line
  }, []);

  const connectZilPay = (ignoreError: boolean) => {
    runConnectTask(async () => {
      if (!!wallet) return;

      const zilPay = (window as any).zilPay;
      if (typeof zilPay === "undefined") {
        if (!ignoreError)
          throw new Error("ZilPay extension not installed");
        return;
      }

      const result = await zilPay.wallet.connect();
      if (result !== zilPay.wallet.isConnect) {
        if (!ignoreError)
          throw new Error("ZilPay could not be connected to.");
        return;
      }

      const walletResult: ConnectWalletResult = await connectWalletZilPay(
        zilPay
      );
      if (walletResult.error) throw walletResult.error;

      if (walletResult.wallet) {
        const { wallet } = walletResult;
        const { network } = wallet;
        dispatch(actions.Blockchain.initialize({ network, wallet }));
        dispatch(actions.Wallet.update({ wallet }));
        return;
      }
    });
  };

  const buttonContent = !!wallet ? (
    <span>
      <FiberManualRecordIcon className={classes.dotIcon} />
      {truncate(wallet!.addressInfo.bech32, 6, 4)}
    </span>
  ) : (
    <span className={classes.connectText}>CONNECT</span>
  );

  const handleConnectWallet = (event?: React.MouseEvent<HTMLElement>) => {
    // if wallet connected, show disconnect button
    if (!!wallet) {
      setAnchorEl(anchorEl ? null : event?.currentTarget ?? null);
    } else {
      // connect to zilpay
      connectZilPay(!event);
    }
  };

  const open = Boolean(anchorEl);

  const handleDisconnectWallet = () => {
    dispatch(actions.Blockchain.initialize({ wallet: null, network }));
    dispatch(actions.Wallet.update({ wallet: null }));
    setAnchorEl(null);
  };

  return (
    <Box className={cls(classes.root, { [classes.brownBg]: !!open })}>
      <Button
        className={classes.connectWalletButton}
        onClick={handleConnectWallet}
        disableFocusRipple
      >
        {isLoading ? (
          <CircularProgress size={18} className={classes.progress} />
        ) : (
          <Typography variant="h3">{buttonContent}</Typography>
        )}
      </Button>
      <Popper open={open} placement="bottom-start" anchorEl={anchorEl}>
        <Paper
          className={classes.disconnectBackground}
          style={{ width: anchorEl?.scrollWidth }}
        >
          <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
            <Button
              className={classes.disconnectButton}
              onClick={handleDisconnectWallet}
            >
              disconnect
            </Button>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </Box>
  );
};


const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  dotIcon: {
    color: "#ADFF00",
    fontSize: "1rem",
    verticalAlign: "middle",
    marginTop: "2px",
    marginRight: theme.spacing(0.8),
  },
  connectWalletButton: {
    borderRadius: "87px",
    padding: "4px 20px",
    backgroundColor: "#511500",
    minHeight: 44,
    minWidth: 140,
    textTransform: "none",
    "& .MuiTypography-root": {
      fontWeight: 800,
      margin: theme.spacing(0.5),
      fontSize: "20px",
      lineHeight: "30px",
      color: "#FFFFFF",
    },
    "&:hover": {
      backgroundColor: "#511500",
    },
  },
  progress: {
    color: "#FFFFFF",
  },
  connectText: {
    fontWeight: 900,
  },
  disconnectBackground: {
    backgroundColor: "#893416",
    borderRadius: "0px 0px 12px 12px",
    display: "flex",
    justifyContent: "center",
    padding: "2px 0",
  },
  brownBg: {
    backgroundColor: "#893416",
    borderRadius: "25px 25px 0px 0px",
  },
  disconnectButton: {
    borderRadius: "87px",
    padding: "4px 20px",
    backgroundColor: "#511500",
    fontFamily: "'M PLUS Rounded 1c', sans-serif",
    fontWeight: 800,
    margin: theme.spacing(0.6, 0),
    fontSize: "18px",
    lineHeight: "30px",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#511500",
    },
  },
}));

export default ConnectWalletButton;
