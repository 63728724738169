import { Box, Button, CircularProgress, InputAdornment, makeStyles, OutlinedInput, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddRounded";
import CallMissedOutgoingIcon from "@material-ui/icons/CallMissedOutgoingRounded";
import RemoveIcon from "@material-ui/icons/RemoveRounded";
import { actions } from "app/store";
import { useAsyncTask, useRedux, useTaskSubscriber } from "app/utils";
import { SaleTime } from "app/utils/constants";
import cls from "classnames";
import { ConnectWalletResult, connectWalletZilPay } from "core/wallet";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ConnectWalletButton from "../ConnectWalletButton";
import FlipClock from "../FlipClock";
import TopBar from "../TopBar";
import { ReactComponent as BearSvg } from "./basic-bear.svg";
import { MintDialog } from "./components";
import { ReactComponent as TinyBearSVG } from "./components/MintDialog/asset/tbm-icon-bear.svg";
import bgImage from "./hero-bg.png";

const MAX_MINT_PER_TX = 10;

const Hero: React.FC<React.HTMLAttributes<HTMLDivElement>> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const network = useRedux((state) => state.blockchain.network);
  const wallet = useRedux((state) => state.wallet.wallet);
  const tokenState = useRedux((state) => state.token);
  const [mintQty, setMintQty] = useState<number>(1);
  const { enqueueSnackbar } = useSnackbar();

  const startTime = dayjs.unix(SaleTime[network].start / 1000);
  const endTime = dayjs.unix(SaleTime[network].end / 1000);
  const rawRevealTime = SaleTime[network].reveal ? dayjs.unix(SaleTime[network].reveal! / 1000) : undefined;

  const [saleStartTime, setSaleStartTime] = useState<dayjs.Dayjs>(startTime);
  const [saleEndTime, setSaleEndTime] = useState<dayjs.Dayjs>(endTime);
  const [revealTime, setRevealTime] = useState<dayjs.Dayjs | undefined>(rawRevealTime);
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const { reservedSupply, giveawaySupply, currentSupply, totalSupply, saleActive } = tokenState;
  const availableSupply = totalSupply - reservedSupply - (currentSupply - giveawaySupply);
  const now = dayjs();

  const soldOut = totalSupply > 0 && availableSupply === 0;

  useEffect(() => {
    if (endTime.isBefore(dayjs())) return;

    const timer = setInterval(() => {
      setSaleStartTime(dayjs(startTime));
      setSaleEndTime(dayjs(endTime));
      if (rawRevealTime)
        setRevealTime(dayjs(rawRevealTime))

      if (!revealTime && endTime.isBefore(dayjs())) clearInterval(timer);
      else if (revealTime?.isBefore(dayjs())) clearInterval(timer);
    }, 500);
    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, [network]);

  const [runConnectTask] = useAsyncTask("connectWalletZilPay", (error) => {
    enqueueSnackbar("Error connecting to ZilPay");
  });
  const [isInitializing] = useTaskSubscriber(
    "initChain",
    "connectWalletZilPay"
  );

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const qty = event.target.valueAsNumber;
    setMintQty(qty);
  };

  const onInputBlur = () => {
    if (isNaN(mintQty) || mintQty === 0) {
      setMintQty(1);
    }
  };

  const handleShowDialog = () => {
    if (!wallet) {
      connectZilPay();
      return;
    }
    if (mintQty <= 10) {
      setShowDialog(true);
    }
  };

  const connectZilPay = () => {
    runConnectTask(async () => {
      if (!!wallet) return;

      const zilPay = (window as any).zilPay;
      if (typeof zilPay === "undefined")
        throw new Error("ZilPay extension not installed");

      const result = await zilPay.wallet.connect();
      if (result !== zilPay.wallet.isConnect)
        throw new Error("ZilPay could not be connected to.");

      const walletResult: ConnectWalletResult = await connectWalletZilPay(
        zilPay
      );
      if (walletResult.error) throw walletResult.error;

      if (walletResult.wallet) {
        const { wallet } = walletResult;
        const { network } = wallet;
        dispatch(actions.Blockchain.initialize({ network, wallet }));
        return;
      }
    });
  };

  const mintButtonContent = useMemo(() => {
    if (!wallet) return "CONNECT";
    if (!saleActive) return "SOON";
    return "MINT";
  }, [wallet, saleActive]);

  const handleAddQty = () => {
    if (mintQty < 10) {
      setMintQty(mintQty + 1);
    }
  };

  const handleSubtractQty = () => {
    if (mintQty === 1) return;
    setMintQty(mintQty - 1);
  };

  const isDisabled = () => {
    return isInitializing || mintQty > 10 || !saleActive;
  };

  return (
    <section id="hero" className={classes.root}>
      <img
        alt="Sunrise Background"
        src={bgImage}
        className={classes.background}
      />
      <Box className={classes.wrapper}>
        <TopBar />
        <Box className={classes.container}>
          <Box className={classes.heroBear}>
            <BearSvg className={classes.heroBearSvg} />
          </Box>

          <Box display="flex" flexDirection="column" alignSelf="center">
            <Typography variant="h1" className={classes.heroText}>
              The ONLY bear <br />
              you'll need to <br />
              get through a <br />
              <span className={classes.bearMarketText}>
                BEAR MARKET
                <CallMissedOutgoingIcon
                  fontSize="inherit"
                  className={classes.callMissedOutgoingIcon}
                />
              </span>
            </Typography>

            {saleEndTime.isBefore(now) && revealTime?.isAfter(now) && (
              <Typography variant="h3" className={classes.redText}>
                REVEALING IN…
              </Typography>
            )}

            {!soldOut && <FlipClock />}

            {/* {!soldOut && saleEndTime.isBefore(now) && (
              <Fragment>
                <Typography variant="h3" className={classes.redText}>
                  FLASH SALE ENDED
                </Typography>
                <Typography variant="h5" className={classes.redText}>
                  Updates coming soon!
                </Typography>
              </Fragment>
            )} */}

            {saleEndTime.isBefore(now) && revealTime?.isBefore(now) && (
              <Button
                className={classes.mintButton}
                to="/gallery"
                component={Link}
                style={{ textTransform: "none", textDecoration: "none" }}
                disableFocusRipple
              >
                <Typography variant="h1">VIEW MY BEARS</Typography>
              </Button>
            )}

            {soldOut && !isInitializing && (
              <Typography variant="h3" className={classes.redText}>
                WE ARE SOLD OUT!
              </Typography>
            )}

            {wallet && saleActive && saleEndTime.isAfter(now) && !soldOut && (
              <Box className={classes.mintBearBox}>
                <Typography variant="h1" className={classes.heroText}>
                  Left to mint your bear:
                </Typography>

                <OutlinedInput
                  className={classes.inputBox}
                  placeholder={"1"}
                  onChange={onInputChange}
                  onBlur={onInputBlur}
                  value={mintQty.toString()}
                  type="number"
                  inputProps={{ min: "1", style: { textAlign: "center" } }}
                  endAdornment={
                    <InputAdornment position="end">
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        className={classes.toggleQtyBox}
                      >
                        <Button
                          onClick={handleAddQty}
                          className={cls(
                            classes.toggleQtyButton,
                            classes.addButton
                          )}
                          endIcon={
                            <AddIcon className={classes.toggleQtyIcon} />
                          }
                          disableRipple
                        />
                        <Button
                          onClick={handleSubtractQty}
                          className={cls(
                            classes.toggleQtyButton,
                            classes.subtractButton
                          )}
                          endIcon={
                            <RemoveIcon className={classes.toggleQtyIcon} />
                          }
                          disableRipple
                        />
                      </Box>
                    </InputAdornment>
                  }
                />
              </Box>
            )}

            {saleStartTime.isBefore(now) && saleEndTime.isAfter(now) && !soldOut && (
              <Button
                className={classes.mintButton}
                disabled={isDisabled()}
                onClick={handleShowDialog}
                disableFocusRipple
              >
                {isInitializing ? (
                  <CircularProgress size={30} className={classes.progress} />
                ) : (
                  <Typography variant="h1">{mintButtonContent}</Typography>
                )}
              </Button>
            )}

            {!isInitializing && saleActive && saleEndTime.isAfter(now) && !soldOut && (
              <Typography variant="h1" className={classes.qtyLeftText}>
                {availableSupply} / {totalSupply}{" "}
                <TinyBearSVG className={classes.tinyBear} /> left
              </Typography>
            )}

            {mintQty > MAX_MINT_PER_TX && (
              <Typography variant="h1" className={classes.bearWarningText}>
                Bear Warning: That's too many bears!
                <br /> Adopt up to 10 bears at any one time.
              </Typography>
            )}
            {wallet && isSm && (
              <Box display="flex" justifyContent="center" mt={1} mb={8}>
                <ConnectWalletButton />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <MintDialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        amount={mintQty}
      />
    </section>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiTypography-root": {
      fontFamily: '"Baloo Paaji 2", cursive',
    },
    "& .MuiOutlinedInput-input": {
      fontFamily: '"Baloo Paaji 2", cursive',
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
    },
    minWidth: 320,
    position: "relative",
    backgroundColor: "#FCCC14",
    minHeight: "100vh",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      minHeight: "none",
    },
  },
  background: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  wrapper: {
    position: "relative",
    width: "100%",
    minHeight: "calc(100% - 10px)",
    borderBottom: "10px solid #FF5252",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  container: {
    marginTop: "auto",
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      marginTop: "unset",
      flex: 1,
    },
  },
  heroText: {
    color: "#511500",
    fontSize: "35px",
    lineHeight: "42px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      fontSize: "28px",
      lineHeight: "35px",
    },
  },
  bearWarningText: {
    color: "#FF5252",
    fontSize: "20px",
    lineHeight: "25px",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  bearMarketText: {
    fontSize: "48px",
    lineHeight: "56px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "35px",
      lineHeight: "50px",
    },
  },
  redText: {
    color: "#FF5252",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  callMissedOutgoingIcon: {
    color: "#D39367",
    verticalAlign: "middle",
  },
  toggleQtyButton: {
    backgroundColor: "#FF5252",
    borderRadius: "0px",
    padding: "4px 16px 4px 8px",
    "&:hover": {
      backgroundColor: "#FF5252",
    },
    height: 38,
    marginRight: "-1px",
    [theme.breakpoints.down("sm")]: {
      padding: "4px 8px 4px 0px",
      height: 24,
      "&.MuiButton-root": {
        minWidth: "40px",
      },
    },
  },
  addButton: {
    borderTopRightRadius: "20px",
  },
  subtractButton: {
    borderBottomRightRadius: "20px",
  },
  toggleQtyBox: {
    height: 80,
    [theme.breakpoints.down("sm")]: {
      height: 53,
    },
  },
  toggleQtyIcon: {
    color: "#FFFFFF",
    fontSize: "32px!important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "25px!important",
    },
  },
  heroBear: {
    display: "flex",
    alignItems: "flex-end",
    alignSelf: "flex-end",
    position: "relative",
    height: "80vh",
    width: "80vh",
    maxWidth: "50vw",
    maxHeight: "50vw",
    marginRight: "10%",
    [theme.breakpoints.down("md")]: {
      height: "70vh",
      width: "70vh",
      marginRight: theme.spacing(3),
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
      width: "100%",
      maxWidth: 320,
      maxHeight: 320,
      flex: 1,
      marginTop: "auto",
    },
  },
  heroBearSvg: {
    height: "100%",
  },
  progress: {
    color: "#FFFFFF",
  },
  inputBox: {
    marginTop: theme.spacing(1),
    border: "5px solid #FF5252",
    borderRadius: "20px",
    height: 80,
    width: 250,
    color: "#FF5252",
    backgroundColor: "#FFFFFF",
    "& input": {
      padding: "17.5px 14px",
      fontSize: "40px",
      height: "0.5rem",
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "&:[type=number]": {
        "-moz-appearance": "textfield",
      },
    },
    "&.Mui-focused": {
      caretColor: "#FF5252",
    },
    [theme.breakpoints.down("sm")]: {
      alignSelf: "center",
      height: 53,
      width: 200,
      "& input": {
        padding: "10px 7px",
        fontSize: "35px",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  mintButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    height: 70,
    width: 300,
    borderRadius: "20px",
    backgroundColor: "#FF5252",
    "& .MuiTypography-root": {
      fontSize: "35px",
      color: "#FFFFFF",
    },
    "&:hover": {
      backgroundColor: "#FF5252",
    },
    "&.MuiButton-root.Mui-disabled": {
      backgroundColor: "rgba(255, 82, 82, 0.7)",
    },
    [theme.breakpoints.down("sm")]: {
      alignSelf: "center",
      height: 53,
      width: 200,
      "& .MuiTypography-root": {
        fontSize: "20px",
      },
    },
  },
  mintBearBox: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1.25),
    },
  },
  qtyLeftText: {
    fontSize: "25px",
    lineHeight: "40px",
    color: "#511500",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      lineHeight: "35px",
      textAlign: "center",
    },
  },
  tinyBear: {
    height: 26,
    width: 26,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginBottom: "3px",
    verticalAlign: "middle",
    "& #bear": {
      fill: "#D39367",
    },
    [theme.breakpoints.down("sm")]: {
      height: 22,
      width: 22,
    },
  },
}));

export default Hero;
