import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { TopBar } from "app/components";
import { ReactComponent as BasicBearSVG } from "app/components/Hero/basic-bear.svg";
import { actions } from "app/store";
import { NftMetadata } from "app/store/types";
import {
  SimpleMap,
  useAsyncTask,
  useRedux,
  useTaskSubscriber,
} from "app/utils";
import { Dayjs } from "dayjs";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { connectWalletZilPay } from "../../../core/wallet";
import { CustomLoader } from "../Hero/components/MintDialog/components";
import NftCard from "../NftCard";
import heroBackgroundNarrow from "./hero-bg-narrow.png";
import { Link } from "react-router-dom";
import plankButtonBg from "../FAQ/tbm-asset-plank-small.svg";
import { useSnackbar } from "notistack";

const Gallery: React.FC<React.HTMLAttributes<HTMLDivElement>> = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [runConnectTask, connecting] = useAsyncTask("connectWalletZilPay", (error) => {
    enqueueSnackbar("Error connecting to ZilPay");
  });
  const tokenState = useRedux((state) => state.token);
  const [
    revealedTokenIds,
    setRevealedTokenIds,
  ] = useState<SimpleMap<Dayjs> | null>(null);
  const wallet = useRedux((state) => state.wallet.wallet);
  const dispatch = useDispatch();
  const [isInitializing] = useTaskSubscriber("initChain");
  const [isLoading] = useTaskSubscriber("initChain", "updateToken");

  const tokens = Object.values(tokenState.tokens);

  const { revealedTokens, newTokens, canRevealCount } = useMemo(() => {
    const revealedTokens: NftMetadata[] = [];
    const newTokens: NftMetadata[] = [];

    let canRevealCount = 0;

    for (const token of tokens) {
      if (!revealedTokenIds?.[token.id] && token.image) canRevealCount++;

      if (revealedTokenIds?.[token.id] || token.revealedAt) revealedTokens.push(token);
      else newTokens.push(token);
    }

    return {
      revealedTokens,
      newTokens,
      canRevealCount,
    };
  }, [revealedTokenIds, tokens]);

  useEffect(() => {
    if (revealedTokenIds) return;
    setRevealedTokenIds(tokenState.revealedTokens);

    // eslint-disable-next-line
  }, [tokenState.revealedTokens, revealedTokens]);

  const connectZilPay = () => {
    runConnectTask(async () => {
      if (!!wallet) return;

      const zilPay = (window as any).zilPay;
      if (typeof zilPay === "undefined")
        throw new Error("ZilPay extension not installed");

      const result = await zilPay.wallet.connect();
      if (result !== zilPay.wallet.isConnect)
        throw new Error("ZilPay could not be connected to.");

      const walletResult = await connectWalletZilPay(zilPay);
      if (walletResult.error) throw walletResult.error;

      if (walletResult.wallet) {
        const { wallet } = walletResult;
        const { network } = wallet;
        dispatch(actions.Blockchain.initialize({ network, wallet }));
        dispatch(actions.Wallet.update({ wallet }));
        return;
      }
    });
  };

  let walletLoading = connecting || isLoading;

  return (
    <Box className={classes.root}>
      <Box className={classes.wrapper}>
        <TopBar />
        <header className={classes.header}>
          <Container maxWidth="lg">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              className={classes.headerBox}
            >
              <BasicBearSVG className={classes.basicBear} />
              <Box>
                <Typography className={classes.headerText}>
                  Now which bears did you get?
                </Typography>
                {canRevealCount > 0 && !isLoading && (
                  <Typography className={classes.hintText}>
                    Hint: Tap to reveal!
                  </Typography>
                )}
              </Box>
            </Box>
          </Container>
        </header>
      </Box>

      <section id="gallery" className={classes.gallery}>
        <Container maxWidth="lg">
          {isLoading && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              <CustomLoader large={true} />
              <Typography className={classes.loadingText}>
                {isInitializing ? "Connecting" : "Loading..."}
              </Typography>
            </Box>
          )}
          {!isLoading && !!tokens.length && (
            <Fragment>
              <Grid container spacing={2}>
                {newTokens.map((token) => (
                  <Grid
                    item
                    key={token.id}
                    xs={12}
                    md={4}
                    className={classes.gridItem}
                  >
                    <NftCard metadata={token} />
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Box
                    position="relative"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginBottom={4}
                  >
                    <span className={classes.divider} />
                    <Typography
                      align="center"
                      component="p"
                      variant="h1"
                      className={classes.myBearsTitle}
                    >
                      My Bears
                    </Typography>
                    <span className={classes.divider} />
                  </Box>
                </Grid>
                {revealedTokens.map((token) => (
                  <Grid
                    item
                    key={token.id}
                    xs={12}
                    md={4}
                    className={classes.gridItem}
                  >
                    <NftCard metadata={token} showAttributes />
                  </Grid>
                ))}
              </Grid>
            </Fragment>
          )}
          {!isLoading && !tokens.length && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              {wallet ? (
                <Box display="flex" flexDirection="column">
                  <Typography align="center" className={classes.noBearsText}>
                    You have no bears :(
                  </Typography>
                  <Button
                    component={Link}
                    to="/"
                    className={!!wallet && classes.adoptButton}
                    disableRipple
                  >
                    <Typography className={classes.adoptButtonText}>
                      ADOPT NOW
                    </Typography>
                  </Button>
                </Box>
              ) : (
                <Box textAlign="center">
                  <Typography align="center" className={classes.noBearsText}>
                    Connect your wallet
                  </Typography>
                  <Button
                    className={classes.connectButton}
                    onClick={connectZilPay}
                    disableFocusRipple
                    disabled={walletLoading}
                  >
                    {walletLoading ? (
                      <CircularProgress
                        size={18}
                        className={classes.progress}
                      />
                    ) : (
                      <Typography variant="h3">CONNECT</Typography>
                    )}
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </Container>
      </section>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiTypography-root": {
      fontFamily: '"Baloo Paaji 2", cursive',
    },
    display: "flex",
    flex: 1,
    flexDirection: "column",
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "unset",
    },
  },
  wrapper: {
    backgroundImage: `url(${heroBackgroundNarrow})`,
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "100%",
    backgroundPositionX: "center",
    backgroundSize: "100% 100%",
  },
  header: {
    borderBottom: "10px solid #FF5252",
  },
  headerText: {
    color: "#511500",
    fontSize: "40px",
    lineHeight: "60px",
    [theme.breakpoints.down("md")]: {
      fontSize: "30px",
      lineHeight: "45px",
      marginBottom: theme.spacing(2),
      textAlign: "center",
    },
  },
  hintText: {
    color: "#FF5252",
    fontSize: "30px",
    lineHeight: "42px",
    [theme.breakpoints.down("md")]: {
      fontSize: "24px",
      lineHeight: "30px",
      marginBottom: theme.spacing(2),
      textAlign: "center",
    },
  },
  headerBox: {
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
    },
  },
  basicBear: {
    height: "150px",
    width: "200px",
    marginBottom: "-1px",
  },
  gallery: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      minHeight: "300px",
    },
    flex: 1,
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  progress: {
    color: "#FFFFFF",
  },
  loadingText: {
    marginTop: theme.spacing(2),
    fontSize: "35px",
    lineHeight: "50px",
    color: "#FF5252",
  },
  noBearsText: {
    color: "#511500",
    fontSize: "45px",
    lineHeight: "60px",
    [theme.breakpoints.down("md")]: {
      fontSize: "30px",
      lineHeight: "45px",
    },
  },
  connectButton: {
    borderRadius: "87px",
    padding: "4px 20px",
    backgroundColor: "#511500",
    minHeight: 44,
    minWidth: 140,
    textTransform: "none",
    marginTop: 12,
    "& .MuiTypography-root": {
      fontWeight: 500,
      margin: theme.spacing(0.5),
      fontSize: "20px",
      color: "#FFFFFF",
    },
    "&:hover": {
      backgroundColor: "#511500",
    },
  },
  divider: {
    flex: 1,
    backgroundColor: "#FF5252",
    height: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
  myBearsTitle: {
    position: "relative",
    background: "#fff",
    color: "#FF5252",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    fontSize: "45px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  adoptButton: {
    backgroundImage: `url(${plankButtonBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    backgroundPositionX: "center",
    height: 70,
    width: 280,
    textAlign: "center",
    textDecoration: "none!important",
    alignSelf: "center",
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      height: 60,
      width: 250,
    },
    [theme.breakpoints.down("xs")]: {
      width: 220,
    },
  },
  adoptButtonText: {
    color: "#FFFFFF",
    fontSize: "35px",
    lineHeight: "50px",
    [theme.breakpoints.down("md")]: {
      fontSize: "30px",
      lineHeight: "45px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "28px",
      lineHeight: "42px",
    },
  },
}));

export default Gallery;
