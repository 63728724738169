import BigNumber from "bignumber.js";
import { Network } from "zilswap-sdk/lib/constants";

export const DefaultFallbackNetwork = Network.MainNet;

export const LoadingKeys = {
  connectWallet: [
    "connectWallet",
    "connectWalletZilPay",
    "connectWalletPrivateKey",
    "initWallet",
    "initChain",
  ],
};

export const LocalStorageKeys = {
  PrivateKey: "zilswap:private-key",
  ZilPayConnected: "zilswap:zilpay-connected",
  ZeevesConnected: "zilswap:zeeves-connected",
  RevealedToken: "tbm:revealed-tokens",
};

export const ZilPayNetworkMap = {
  mainnet: Network.MainNet,
  testnet: Network.TestNet,
} as { [index: string]: Network };

export const RPCEndpoints: { [key in Network]: string } = {
  [Network.MainNet]: 'https://api.zilliqa.com',
  [Network.TestNet]: 'https://dev-api.zilliqa.com',
};

export const BIG_ZERO = new BigNumber(0);
export const BIG_ONE = new BigNumber(1);

export const CAROUSEL_AUTOPLAY = 3000; // scroll every 3s
export const PARALLAX_STRENGTH = 500;

export const SaleTime = {
  [Network.MainNet]: {
    start: new Date("2021/09/15 08:00:00 GMT+0").getTime(),
    end: new Date("2021/09/17 08:00:00 GMT+0").getTime(),
    reveal: new Date("2021/09/23 07:00:00 GMT+0").getTime(),
  } as const,
  [Network.TestNet]: {
    start: new Date("2021/09/15 07:21:00 GMT+0").getTime(),
    end: new Date("2021/09/30 10:00:00 GMT+0").getTime(),
    reveal: undefined,
  } as const,
} as const;

export const ContractsBech32 = {
  [Network.MainNet]: {
    Nft: "zil167flx79fykulp57ykmh9gnf3curcnyux6dcj5e",
    GiveawayMinter: "zil13cx3ch8jt4jr0qmxwg3qxjpvc932k749yg0snm",
    CommunityMinter: "zil1eqfdxe0gny2plw3q4m7mdkmcg9wcckyvgyyz72"
  },
  [Network.TestNet]: {
    Nft: "zil1e9yfgt64auz6jkjxhdvwaxc2v7c0su06mpfgjs",
    GiveawayMinter: "zil16zgmg5v8xtvwjlwad4pel6l0vhfxcqrzyst6wr",
    CommunityMinter: "zil1cyn55guyanw764mytn87v6acqtt24ee45jacrw",
  }
}
